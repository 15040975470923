/* eslint-disable global-require */

import ReleaseitLogo from "@releaseit/assets/images/releaseit-logo-green.svg";

const SOCIALS = [
  {
    label: "Instagram",
    icon: require("@releaseit/assets/icons/social-instagram.svg").default,
    href: "https://www.instagram.com/releaseitau",
  },
  {
    label: "Twitter",
    icon: require("@releaseit/assets/icons/social-twitter.svg").default,
    href: "https://twitter.com/releaseitau",
  },
  {
    label: "Facebook",
    icon: require("@releaseit/assets/icons/social-facebook.svg").default,
    href: "https://www.facebook.com/releaseitau",
  },
  {
    label: "LinkedIn",
    icon: require("@releaseit/assets/icons/social-linkedin.svg").default,
    href: "https://www.linkedin.com/company/releaseit",
  },
];

export function Socials() {
  return (
    <div className="bg-gray-800 md:bg-black">
      <div className="container flex flex-col items-center justify-between gap-y-6 py-6 md:flex-row md:py-4">
        {/* Logo */}
        <ReleaseitLogo height={40} />

        {/* Social Buttons */}
        <nav>
          <ul className="flex items-center gap-x-4">
            {SOCIALS.map((social) => (
              <li key={social.label}>
                <a
                  href={social.href}
                  aria-label={social.label}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <social.icon className="h-10 w-10" />
                </a>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  );
}
