import NextLink from "next/link";
import { useRouter } from "next/router";

import { Button, Divider } from "@releaseit/ui";

import SearchIcon from "@releaseit/assets/icons/search.svg";
import ReleaseitLogo from "@releaseit/assets/images/releaseit-logo-green.svg";

export function SiteWideSearch() {
  return (
    <div className="bg-gray-800">
      <div className="container flex items-center justify-between gap-x-32 py-4">
        <NextLink href="/" prefetch={false}>
          <a
            aria-label="home"
            className="rounded-sm focus-visible:outline-none focus-visible:ring-4 focus-visible:ring-primary-green/25"
          >
            <ReleaseitLogo height={36} />
          </a>
        </NextLink>

        <SearchBar />
      </div>
    </div>
  );
}

export function SearchBar() {
  const router = useRouter();

  // This submit handler is only run when not on "/search"
  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const data = new FormData(e.currentTarget);
    const category = data.get("category") as string;
    const query = data.get("query") as string;

    router.push(
      {
        pathname: "/search",
        query: { q: query, ...(category && { category }) },
      },
      undefined,
      { shallow: true }
    );
  }

  return (
    <form
      onSubmit={handleSubmit}
      onFocus={() => router.prefetch("/search")}
      className="
        flex h-12 w-full max-w-3xl
        overflow-hidden rounded-md border border-black
        bg-white
        ring-primary-green/25 focus-within:ring-4
      "
    >
      <select
        id="category"
        name="category"
        defaultValue={router.query.category}
        className="border-none text-sm focus:ring-0"
      >
        <option value="">All</option>
        <option value="baby-and-kids">Baby &amp; Kids</option>
        <option value="fashion">Fashion</option>
        <option value="furniture">Furniture</option>
        <option value="garden-and-tools">Garden &amp; Tools</option>
        <option value="home">Home</option>
        <option value="office-and-storage">Office &amp; Storage</option>
        <option value="party-and-events">Party &amp; Events</option>
        <option value="sports-and-recreation">Sports &amp; Recreation</option>
        <option value="technology">Technology</option>
        <option value="vehicles">Vehicles</option>
      </select>

      <Divider orientation="vertical" className="mx-4 border-y-8 border-white" />

      <div className="relative grow">
        <div className="absolute inset-y-0 left-0 flex items-center justify-center">
          <SearchIcon className="h-6 w-6" />
        </div>

        <input
          id="query"
          name="query"
          type="search"
          placeholder="Search"
          defaultValue={router.query.q}
          required
          minLength={2}
          className="h-full w-full border-none pl-10 text-sm focus:ring-0"
        />
      </div>

      <Button
        type="submit"
        size="sm"
        startIcon={<SearchIcon className="h-6 w-6" />}
        className="rounded-l-none border-l-black"
      >
        Search
      </Button>
    </form>
  );
}
