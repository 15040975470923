import NextLink from "next/link";
import { useRouter } from "next/router";

import { cn } from "@releaseit/ui";

import ExploreIcon from "@releaseit/assets/icons/compass.svg";
import MoreIcon from "@releaseit/assets/icons/ellipsis-h.svg";
import CategoriesIcon from "@releaseit/assets/icons/menu.svg";
import ListItemIcon from "@releaseit/assets/icons/plus-circle.svg";
import SearchIcon from "@releaseit/assets/icons/search.svg";

export function MobileNavBar() {
  return (
    <div id="mobile-navbar" className="mt-14 md:hidden">
      <nav className="fixed inset-x-0 bottom-0 z-10 h-14 border-t bg-white">
        <div role="navigation" className="grid h-full grid-cols-5">
          <NavItem href="/" icon={ExploreIcon}>
            Explore
          </NavItem>
          <NavItem href="/category" icon={CategoriesIcon}>
            Categories
          </NavItem>
          <NavItem href="/search" icon={SearchIcon}>
            Search
          </NavItem>
          <NavItem href="/create-listing" icon={ListItemIcon}>
            List Item
          </NavItem>
          <NavItem href="/my-account" icon={MoreIcon}>
            More
          </NavItem>
        </div>
      </nav>
    </div>
  );
}

type NavItemProps = {
  /** React children prop. */
  children: React.ReactNode;

  /** Href to be passed to the link. */
  href: string;

  /** Icon to display above the text. */
  icon: React.ComponentType<React.SVGAttributes<SVGElement>>;
};

function NavItem({ href, icon: Icon, children }: NavItemProps) {
  const { pathname } = useRouter();

  return (
    <NextLink href={href} prefetch={false}>
      <a
        className={cn(
          "inline-flex w-full flex-col items-center justify-center text-sm",
          pathname === href ? "text-mid-green" : "text-gray-800"
        )}
      >
        <Icon />
        <span>{children}</span>
      </a>
    </NextLink>
  );
}
