import NextLink from "next/link";

import { Collapsible, Divider, Link } from "@releaseit/ui";

import ChevronDownIcon from "@releaseit/assets/icons/chevron-down.svg";

import { FOOTER_GROUPS } from "./data";

export function FooterBelow() {
  return (
    <div className="bg-gray-800">
      <div className="container flex flex-col gap-y-8 pt-4 pb-12 md:pt-6">
        <FooterNav />
        <Divider className="invisible bg-gray-700 md:visible md:mt-4" />
        <Legal />
      </div>
    </div>
  );
}

function FooterNav() {
  return (
    <>
      {/* Desktop Nav */}
      <nav className="hidden gap-x-4 md:flex">
        {FOOTER_GROUPS.map((group) => (
          <div className="flex-1 space-y-4 text-sm" key={group.title}>
            <h3 className="font-medium text-gray-500">{group.title}</h3>

            <ul className="space-y-2 text-white">
              {group.links.map((link) => (
                <li key={link.href}>
                  <NextLink href={link.href} passHref prefetch={false}>
                    <Link variant="unstyled" isSubtle>
                      {link.title}
                    </Link>
                  </NextLink>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </nav>

      {/* Mobile Nav */}
      <nav className="block px-8 md:hidden">
        {FOOTER_GROUPS.map((group) => (
          <Collapsible key={group.title}>
            <Collapsible.Trigger
              className="
                  flex w-full items-center justify-between border-b
                  border-gray-600 py-3 text-white
                  ring-white/25 transition focus-visible:outline-none focus-visible:ring-4
                "
            >
              <span className="truncate font-medium">{group.title}</span>
              <ChevronDownIcon className="h-8 w-8" />
            </Collapsible.Trigger>

            <Collapsible.Content asChild>
              <ul className="space-y-2 py-2 text-white">
                {group.links.map((link) => (
                  <li key={link.href}>
                    <NextLink href={link.href} passHref prefetch={false}>
                      <Link variant="unstyled" isSubtle>
                        {link.title}
                      </Link>
                    </NextLink>
                  </li>
                ))}
              </ul>
            </Collapsible.Content>
          </Collapsible>
        ))}
      </nav>
    </>
  );
}

function Legal() {
  return (
    <div className="space-y-4 text-sm text-white">
      <p className="px-2 text-center md:px-0 md:text-left">
        &ldquo;We don&apos;t buy it&rdquo; and &ldquo;We don&apos;t buy it. You shouldn&apos;t
        either&rdquo; are trademarks of Releaseit Holdings Pty Ltd.
      </p>

      <div className="flex flex-col items-center gap-4 md:flex-row">
        <strong>©2022 Releaseit</strong>

        <nav className="flex gap-x-2">
          <NextLink href="/page/terms-of-use" passHref prefetch={false}>
            <Link variant="unstyled" isSubtle>
              Terms & Conditions
            </Link>
          </NextLink>

          <span aria-hidden>|</span>

          <NextLink href="/page/privacy-policy" passHref prefetch={false}>
            <Link variant="unstyled" isSubtle>
              Privacy
            </Link>
          </NextLink>
        </nav>
      </div>
    </div>
  );
}
