import { useQuery } from "react-query";

export function HeaderPromoBar() {
  const { data } = useQuery({
    queryKey: "banner",
    queryFn: () => fetch("/api/banner").then((res) => res.text()),
    staleTime: Infinity,
  });

  return (
    <div
      id="header-promo-bar"
      className="prose max-w-none bg-primary-green p-2 text-center text-sm text-black prose-a:font-normal prose-a:text-black md:text-base"
      // This gets sanitized on the server and is safe to use.
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: data ?? "Loading..." }}
    />
  );
}
