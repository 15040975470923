import * as NavigationMenuPrimitive from "@radix-ui/react-navigation-menu";
import NextLink from "next/link";

import { cn, Link } from "@releaseit/ui";

import ChevronRightIcon from "@releaseit/assets/icons/chevron-right.svg";
import MenuIcon from "@releaseit/assets/icons/menu.svg";

import { useNavigationQuery } from "@/generated/graphql";
import { type TopLevelNavCategory } from "@/features/category";

export function CategoryMegaMenu() {
  const { data } = useNavigationQuery({}, { staleTime: Infinity });
  const nav = data?.Navigation;

  return (
    <RootCategoryMenu>
      <RootCategoryMenuTrigger />
      <RootCategoryMenuContent>
        <SubCategoryMenu>
          {nav?.categories.map((category) => (
            <SubCategoryMenuItem key={category.title}>
              <SubCategoryMenuTrigger label={category.title} />
              <SubCategoryMenuContent category={category} />
            </SubCategoryMenuItem>
          ))}
        </SubCategoryMenu>
      </RootCategoryMenuContent>
    </RootCategoryMenu>
  );
}

/** Internal components */

function RootCategoryMenu({ children }: { children: React.ReactNode }) {
  return (
    <NavigationMenuPrimitive.Root className="relative">
      <NavigationMenuPrimitive.Item className="h-full list-none">
        {children}
      </NavigationMenuPrimitive.Item>
    </NavigationMenuPrimitive.Root>
  );
}

function RootCategoryMenuTrigger() {
  return (
    <>
      <NextLink href="/category">
        <a className="sr-only">categories</a>
      </NextLink>

      <NavigationMenuPrimitive.Trigger className="flex h-full items-center gap-x-2 ring-inset ring-black/25 focus:outline-none focus-visible:ring-4">
        <MenuIcon className="h-5 w-5" />
        <span>Categories</span>
      </NavigationMenuPrimitive.Trigger>
    </>
  );
}

function RootCategoryMenuContent({ children }: { children: React.ReactNode }) {
  return (
    <NavigationMenuPrimitive.Content
      style={{ perspective: 2000 }}
      className="
        absolute top-full left-0 z-10 origin-top
        radix-state-open:animate-slide-down
        radix-state-closed:animate-scale-out-content
      "
    >
      {children}
    </NavigationMenuPrimitive.Content>
  );
}

function SubCategoryMenu({ children }: { children: React.ReactNode }) {
  return (
    <NavigationMenuPrimitive.Sub orientation="vertical" className="relative flex w-64 flex-col">
      <NavigationMenuPrimitive.Viewport
        style={{
          width: "calc(85vw - 270px)",
          maxWidth: 896,
          height: "max(var(--radix-navigation-menu-viewport-height), 100%)",
          background: "linear-gradient(90deg, #fff 75%, #edeef0 75%)",
        }}
        className="
          absolute left-full overflow-hidden shadow-card transition-[height]
          radix-state-open:animate-enter-from-left
          radix-state-closed:animate-exit-to-left
        "
      />

      <NavigationMenuPrimitive.List className="z-10 bg-gray-100 shadow-card">
        {children}
      </NavigationMenuPrimitive.List>
    </NavigationMenuPrimitive.Sub>
  );
}

function SubCategoryMenuItem({ children }: { children: React.ReactNode }) {
  return <NavigationMenuPrimitive.Item>{children}</NavigationMenuPrimitive.Item>;
}

function SubCategoryMenuTrigger({ label }: { label: string }) {
  return (
    <NavigationMenuPrimitive.Trigger className="inline-flex h-12 w-full items-center justify-between bg-off-white pl-6 pr-3 text-black ring-inset ring-black/25 focus-visible:outline-none focus-visible:ring-4 radix-state-open:bg-gray-100">
      <span>{label}</span>
      <ChevronRightIcon className="h-6 w-6" />
    </NavigationMenuPrimitive.Trigger>
  );
}

function SubCategoryMenuContent({ category }: { category: TopLevelNavCategory }) {
  return (
    <NavigationMenuPrimitive.Content
      className="
        absolute top-0 left-0 flex
        radix-motion-from-start:animate-enter-from-top
        radix-motion-to-start:animate-exit-to-top
        radix-motion-from-end:animate-enter-from-bottom
        radix-motion-to-end:animate-exit-to-bottom
      "
    >
      <div className="w-3/4 p-6">
        <div className="flex items-baseline gap-x-4">
          <h2 className="text-2xl font-medium">{category.title}</h2>
          <NextLink href={`/hire/${category.href}`} passHref>
            <NavigationMenuPrimitive.Link asChild>
              <Link variant="neutral" isSubtle>
                View all
              </Link>
            </NavigationMenuPrimitive.Link>
          </NextLink>
        </div>

        <div className="columns-[150px_4] gap-10 py-6">
          {category.children.map((subcategory) => (
            <SubCategoryNavGroup
              key={subcategory.title}
              heading={subcategory.title}
              href={subcategory.href}
              itemType="category"
              items={subcategory.children}
            />
          ))}
        </div>
      </div>

      <div className="w-1/4 p-6 pt-14">
        <SubCategoryNavGroup
          heading="Popular Brands"
          itemType="brand"
          items={category.popularBrands}
        />
      </div>
    </NavigationMenuPrimitive.Content>
  );
}

interface SubCategoryNavGroupProps {
  heading: string;
  href?: string;
  itemType: "category" | "brand";
  items: { title: string; href: string }[];
}

function SubCategoryNavGroup({ heading, itemType, href, items }: SubCategoryNavGroupProps) {
  const hrefBase = itemType === "category" ? "/hire" : "/brand";

  return (
    <div className="mb-5 break-inside-avoid space-y-2">
      <NextLink href={href ? `${hrefBase}/${href}` : "#"} passHref>
        <NavigationMenuPrimitive.Link asChild>
          <Link
            className={cn(
              "block pb-2 text-base font-medium text-black no-underline hover:text-mid-green",
              items.length > 0 && "border-b"
            )}
          >
            {heading}
          </Link>
        </NavigationMenuPrimitive.Link>
      </NextLink>

      {items.length > 0 && (
        <ul className="text-sm">
          {items.map((item) => (
            <li key={item.title} className="truncate py-1.5">
              <NextLink href={`${hrefBase}/${item.href}`} passHref>
                <NavigationMenuPrimitive.Link asChild>
                  <Link className="text-gray-800 no-underline hover:text-mid-green">
                    {item.title}
                  </Link>
                </NavigationMenuPrimitive.Link>
              </NextLink>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
