import { HeaderPromoBar } from "./HeaderPromoBar";
import { NavBar } from "./NavBar";
import { SiteWideSearch } from "./SiteWideSearch";

export function Header() {
  return (
    <>
      <HeaderPromoBar />
      <header id="header" className="hidden md:block">
        <SiteWideSearch />
        <NavBar />
      </header>
    </>
  );
}
