import NextImage from "next/image";
import NextLink from "next/link";
import { useSession } from "next-auth/react";

import { Button, Link } from "@releaseit/ui";

import PlusIcon from "@releaseit/assets/icons/plus.svg";

import { useNavigationQuery } from "@/generated/graphql";

import { CategoryMegaMenu } from "./CategoryMegaMenu";

export function NavBar() {
  const session = useSession();

  return (
    <div className="border-b border-gray-200 bg-gray-100">
      <div className="container grid h-12 grid-cols-[1fr_auto_1fr] gap-x-4">
        {/* Start */}
        <CategoryMegaMenu />

        {/* Middle */}
        <PromoLinks />

        {/* End */}
        <div className="flex items-center gap-x-4 justify-self-end">
          <Button
            as="a"
            href="/create-listing"
            variant="filled-neutral"
            size="sm"
            startIcon={<PlusIcon className="h-4 w-4" />}
          >
            List Item
          </Button>

          {session.status === "authenticated" ? (
            <ProfileLink image={session.data.user.image} />
          ) : (
            <LoginButton />
          )}
        </div>
      </div>
    </div>
  );
}

function PromoLinks() {
  const { data } = useNavigationQuery({}, { staleTime: Infinity });
  const nav = data?.Navigation;

  return (
    <ul className="flex items-center justify-center gap-x-6 text-sm">
      {nav?.promoLinks.map((promoLink) => (
        <li key={promoLink.href}>
          <NextLink href={promoLink.href} passHref prefetch={false}>
            <Link isSubtle variant="unstyled">
              {promoLink.title}
            </Link>
          </NextLink>
        </li>
      ))}
    </ul>
  );
}

function ProfileLink({ image }: { image: string }) {
  return (
    <NextLink href="/my-account" passHref prefetch={false}>
      <Link variant="unstyled" isSubtle className="inline-flex items-center gap-x-2">
        <NextImage
          src={image}
          alt="avatar"
          priority
          unoptimized
          width={32}
          height={32}
          className="rounded-full object-cover"
        />
        <span className="text-sm">My Account</span>
      </Link>
    </NextLink>
  );
}

function LoginButton() {
  return (
    <NextLink href="/login" passHref prefetch={false}>
      <Link variant="unstyled" isSubtle className="mb-0.5">
        Login
      </Link>
    </NextLink>
  );
}
