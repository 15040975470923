import { FooterBelow } from "./FooterBelow";
import { Socials } from "./Socials";

export function Footer() {
  return (
    <footer id="footer">
      <Socials />
      <FooterBelow />
    </footer>
  );
}
