import React from "react";
import * as Sentry from "@sentry/nextjs";
import { useSession } from "next-auth/react";

import { gqlClient } from "@/utils/graphql-client";

export function useAuthRefresh() {
  const { data: session } = useSession();

  // On session change
  React.useEffect(() => {
    // Update the authorization header
    gqlClient.headers.set("Authorization", session ? `Bearer ${session.accessToken}` : "");

    // And set user in sentry
    Sentry.setUser(session ? session.user : null);
  }, [session]);
}
