export const FOOTER_GROUPS = [
  {
    title: "About Us",
    links: [
      {
        title: "Our Company",
        href: "/about",
      },
      {
        title: "How Releaseit Works",
        href: "/page/how-releaseit-works",
      },
      {
        title: "Blog",
        href: "/blog",
      },
      {
        title: "Categories",
        href: "/category",
      },
      {
        title: "Communities",
        href: "/community",
      },
    ],
  },
  {
    title: "For Owners",
    links: [
      {
        title: "List an Item",
        href: "/create-listing",
      },
      {
        title: "Business Portal",
        href: "https://portal.releaseit.com.au",
      },
      {
        title: "Partner with Releaseit",
        href: "/rental-businesses",
      },
    ],
  },
  {
    title: "Support",
    links: [
      {
        title: "FAQs",
        href: "/page/faqs",
      },
      {
        title: "Contact Us",
        href: "/contact",
      },
      {
        title: "Staying COVID Safe",
        href: "/page/staying-covid-safe",
      },
    ],
  },
  {
    title: "Legal",
    links: [
      {
        title: "Privacy Policy",
        href: "/page/privacy-policy",
      },
      {
        title: "Terms of Use",
        href: "/page/terms-of-use",
      },
      {
        title: "Cancellation Policy",
        href: "/page/cancellation-policy",
      },
      {
        title: "Dispute Resolution Policy",
        href: "/page/dispute-resolution-policy",
      },
      {
        title: "Review Policy",
        href: "/page/review-policy",
      },
    ],
  },
];
