import { useAuthRefresh } from "@/features/auth";

import { Footer } from "./footer/Footer";
import { Header } from "./header/Header";
import { MobileNavBar } from "./MobileNavBar";

export const NAVIGATION_QUERY = /* GraphQL */ `
  query NavigationQuery {
    Navigation {
      promoLinks {
        title
        href
      }
      categories {
        __typename
        title
        href
        featured {
          title
          href
        }
        popularBrands {
          title
          href
        }
        children {
          ...NavigationCategoryRecursive
          children {
            ...NavigationCategoryRecursive
            children {
              ...NavigationCategoryRecursive
            }
          }
        }
      }
    }
  }

  fragment NavigationCategoryRecursive on NavigationCategory {
    title
    href
    featured {
      title
      href
    }
  }
`;

export type DefaultLayoutProps = {
  /**
   * React children prop.
   */
  children?: React.ReactNode;
};

export function DefaultLayout({ children }: DefaultLayoutProps) {
  useAuthRefresh();

  return (
    <div className="flex min-h-screen flex-col">
      <Header />
      <main className="grow">{children}</main>
      <Footer />
      <MobileNavBar />
    </div>
  );
}

export default DefaultLayout;
